import React from 'react';
import { Typography, Button } from 'antd';
import Object3DViewer from '../components/Object3DViewerGLB';
import { getChildrenToRender } from './utils';

const { Title, Paragraph } = Typography;

function Content4(props) {
  const { ...tagProps } = props;
  const { dataSource } = tagProps;
  delete tagProps.dataSource;

  const project = {
    name: "Experience the Future of Construction Site Visualization",
    description: "Discover our cutting-edge technology that transforms construction sites into immersive 3D models. Using drones and advanced imaging techniques, we capture precise aerial data to provide a detailed view of your project. Our advanced visualization platform allows you to explore the site virtually, analyze key details, and optimize project planning. Say goodbye to traditional blueprints and embrace the power of realistic 3D visualizations. Revolutionize the way you manage construction projects. Enhance communication, collaboration, and decision-making with our innovative solution. Experience the future of construction site visualization today!",
    technologies: ["Drone", "3D", "AI"],
    client: "Sintra Construction Site"
  };

  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <div {...dataSource.page}>
        <div key="title" {...dataSource.titleWrapper}>
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
        </div>
        <div className="content-wrapper">
        <div className="text-wrapper" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' , fontFamily: "'Poppins', sans-serif"}}>
            <Title level={2}>{project.name}</Title>
            <Paragraph className="description">{project.description}</Paragraph>
            <div className="buttons-wrapper">
              {project.technologies.map((tech, index) => (
                <Button
                  key={index}
                  style={{
                    backgroundColor: "#" + ((1 << 24) * Math.random() | 0).toString(16),
                    color: 'white',
                    margin: '5px'
                  }}
                >
                  {tech}
                </Button>
              ))}
            </div>
            <Paragraph><strong>Location:</strong> {project.client}</Paragraph>
          </div>
          <div className="model-wrapper" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Object3DViewer
              dataSource={dataSource}
              width={1000}
              height={600}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content4;
