import React from 'react';

const RealStateHome = new URL('../../images/real-state/real-state-home.jpg', import.meta.url);
const birdseyePic = new URL('../../images/real-state/birds-eye-view.jpg', import.meta.url);
const marketMastery = new URL('../../images/real-state/market-mastery.jpg', import.meta.url);
const droneDrive = new URL('../../images/real-state/drone-driven.jpg', import.meta.url);
const realStateRevolution = new URL('../../images/real-state/real-state-revolution.jpg', import.meta.url);



export const Banner30DataSource = {
  wrapper: { className: 'banner3 lihrajp3ekm-editor_css' },
  img: { children: RealStateHome },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      // {
      //   name: 'nameEn',
      //   className: 'banner3-name-en',
      //   children: 'Seeking Experience & Engineering Conference',
      // },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: 'Revolutionize Real Estate with Drones & AI',
        texty: true,
        style: { color: '#000000', fontSize: '32px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: 'Innovative solutions for a smarter market',
        style: { color: '#000000', fontSize: '32px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
      // { name: 'button', className: 'banner3-button', children: '立即报名' },
      {
        name: 'time',
        className: 'banner3-time',
        children: 'Smart Insights, Smarter Investments',
        style: { color: '#000000', fontSize: '32px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: birdseyePic,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: "Bird's Eye Brilliance" },
  content: {
    className: 'content1-content',
    children:
      "Unlock a new perspective on real estate with our thorough aerial snapshots. Our drone technology captures high-resolution images and videos from unique vantage points, providing you with a comprehensive view of properties. Whether you're evaluating land for development or assessing existing structures, our aerial snapshots offer valuable insights into property features, surroundings, and potential. Make informed decisions and gain a competitive edge in the real estate market with our bird's eye brilliance.",
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: droneDrive,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'Drone-Driven Diligence' },
  content: {
    className: 'content2-content',
    children:
      "Experience streamlined property inspections that leave no stone unturned with our drone-driven diligence. Our advanced drones equipped with high-resolution cameras and sensors can efficiently and precisely inspect properties, capturing detailed information about roofs, facades, infrastructure, and more. By leveraging drone intelligence, we ensure thorough assessments while saving time and reducing costs compared to traditional inspection methods. Trust our technology to provide you with accurate data for informed property evaluations and due diligence.",
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: marketMastery,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Market Insight Mastery' },
  content: {
    className: 'content1-content',
    children:
      "Stay ahead of the curve in the dynamic real estate market with our astute market analyses powered by bespoke AI models. Our AI algorithms analyze vast amounts of real estate data, including property values, market trends, demographics, and economic indicators. By synthesizing and interpreting this information, we provide you with valuable market insights and actionable recommendations. Whether you're a developer, investor, or real estate professional, our market insight mastery equips you with the knowledge needed to make strategic decisions and seize lucrative opportunities.",
  },
};

export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: realStateRevolution,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'Real Estate Revolution' },
  content: {
    className: 'content2-content',
    children:
      "Join the real estate revolution with our end-to-end solutions that cater to all your real estate needs. From land assessment to property inspection, we are your trusted one-stop partner. Our comprehensive services encompass everything from initial site evaluation and feasibility studies to detailed property inspections and market analysis. By leveraging our expertise and cutting-edge technology, you can streamline your real estate workflows, minimize risks, and maximize returns. Embrace the future of real estate with our revolutionizing solutions.",
  },
};

// export const Content90DataSource = {
//   wrapper: { className: 'home-page-wrapper content9-wrapper' },
//   page: { className: 'home-page content9' },
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [
//       {
//         name: 'image',
//         children:
//           'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
//         className: 'title-image',
//       },
//       { name: 'title', children: '会议日程', className: 'title-h1' },
//     ],
//   },
//   block: {
//     className: 'timeline',
//     children: [
//       {
//         name: 'block0',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '开幕致辞' },
//           content: { className: 'block-content', children: '' },
//         },
//       },
//       {
//         name: 'block1',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//       {
//         name: 'block2',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//       {
//         name: 'block3',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//     ],
//   },
// };
// export const Content120DataSource = {
//   wrapper: { className: 'home-page-wrapper content12-wrapper' },
//   page: { className: 'home-page content12' },
//   OverPack: { playScale: 0.3, className: '' },
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [
//       {
//         name: 'image',
//         children:
//           'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
//         className: 'title-image',
//       },
//       { name: 'title', children: '特别鸣谢', className: 'title-h1' },
//     ],
//   },
//   block: {
//     className: 'img-wrapper',
//     children: [
//       {
//         name: 'block0',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/TFicUVisNHTOEeMYXuQF.svg',
//           },
//         },
//       },
//       {
//         name: 'block1',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/hkLGkrlCEkGZeMQlnEkD.svg',
//           },
//         },
//       },
//       {
//         name: 'block2',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/bqyPRSZmhvrsfJrBvASi.svg',
//           },
//         },
//       },
//       {
//         name: 'block3',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/UcsyszzOabdCYDkoPPnM.svg',
//           },
//         },
//       },
//       {
//         name: 'block4',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/kRBeaICGexAmVjqBEqgw.svg',
//           },
//         },
//       },
//       {
//         name: 'block5',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/ftBIiyJcCHpHEioRvPsV.svg',
//           },
//         },
//       },
//     ],
//   },
// };
