import React from 'react';

const AgricultureHome = new URL('../../images/agriculture/AgricultureHomePic.jpg', import.meta.url);
const HealthCropAgriculturePic = new URL('../../images/agriculture/farmer-making-greenhouse-crop-growth.png', import.meta.url);
const AgricultureWaterPic = new URL('../../images/agriculture/pinpoint-irrigation.jpg', import.meta.url);
const AgricultureSustainablePic = new URL('../../images/agriculture/predict-yield-agriculture.jpg', import.meta.url);
// const AgricultureFuture = new URL('../../images/agriculture/agricultureFuture.jpg', import.meta.url);
const AgricultureFuture2 = new URL('../../images/agriculture/agricultureFuture2.jpg', import.meta.url);

export const Banner30DataSource = {
  wrapper: { className: 'banner3 lihrajp3ekm-editor_css' },
  img: { children: AgricultureHome },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      // {
      //   name: 'nameEn',
      //   className: 'banner3-name-en',
      //   children: ' ',
      //   style: { color: '#000000' },
      // },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: 'Innovative Precision Agriculture Solutions',
        texty: true,
        style: { color: '#000000', fontSize: '32px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: 'Harness the power of drones & AI to transform your agriculture business.',
        style: { color: '#000000', fontSize: '32px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
      // { name: 'button', className: 'banner3-button', children: 'Learn More' },
      {
        name: 'time',
        className: 'banner3-time',
        children: 'Improve Yield, Save Time, and Reduce Costs',
        style: { color: '#000000', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Arial' },
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: HealthCropAgriculturePic,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Sky-high Crop Health' },
  content: { className: 'content1-content', children: "Breathe easy knowing that your crops are under constant, caring watch with our advanced drone technology and AI. Our smart drones tirelessly monitor your fields, capturing high-resolution imagery and data to detect potential issues early on. From identifying signs of disease to monitoring crop growth patterns, our technology keeps you informed and empowers you to make quicker decisions. By staying one step ahead, you can take proactive measures to ensure healthier harvests and maximize your yield. With our solution, your farm will thrive, and you can let your worries take a backseat.  " },
};

export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: AgricultureWaterPic,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'Pinpoint Irrigation' },
  content: { className: 'content2-content', children: "Water is a precious resource, and every drop counts. With our precision irrigation system powered by drone technology, you can ensure that none of it goes to waste. Our drones collect data and provide detailed insights about soil moisture levels, crop water requirements, and field conditions. Based on this information, our system precisely targets irrigation where it is needed the most, optimizing water usage and reducing wastage. By implementing pinpoint irrigation, you not only conserve water but also boost plant growth, improve crop health, and contribute to the preservation of our valuable resources." },
};

export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: AgricultureSustainablePic,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Predict to Profit' },
  content: { className: 'content1-content', children: "Say goodbye to guesswork and make data-driven decisions for your farming operations. Our AI algorithms, fueled by the data collected from our drones, enable precise crop yield predictions. By analyzing factors such as plant health, weather patterns, soil conditions, and historical data, our algorithms provide accurate insights into your expected crop yield. Armed with this knowledge, you can optimize your resource allocation, plan your marketing and sales strategies, and make informed decisions to maximize your profitability. With our technology, you can watch your profits skyrocket as you harness the power of predictive analytics." },
};

export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: AgricultureFuture2,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'Farming for the Future' },
  content: { className: 'content2-content', children: "Embrace the future of farming with our precision farming practices that prioritize sustainability and environmental stewardship. Our technology enables you to minimize the use of fertilizers and pesticides while maintaining optimal crop health. Through data-driven insights gathered by our drones, you can precisely determine the fertilizer requirements of your fields, ensuring efficient nutrient application. Additionally, our system helps monitor pest and disease outbreaks, enabling targeted interventions and reducing the need for widespread pesticide use. By adopting these eco-friendly practices, you increase overall farming efficiency, reduce costs, improve the quality of your produce, and contribute to a healthier planet. We are not just farming smarter; we're farming greener to build a sustainable future for agriculture." },
};

// export const Content90DataSource = {
//   wrapper: { className: 'home-page-wrapper content9-wrapper' },
//   page: { className: 'home-page content9' },
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [
//       {
//         name: 'image',
//         children:
//           'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
//         className: 'title-image',
//       },
//       { name: 'title', children: '会议日程', className: 'title-h1' },
//     ],
//   },
//   block: {
//     className: 'timeline',
//     children: [
//       {
//         name: 'block0',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '开幕致辞' },
//           content: { className: 'block-content', children: '' },
//         },
//       },
//       {
//         name: 'block1',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//       {
//         name: 'block2',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//       {
//         name: 'block3',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//     ],
//   },
// };
export const Content120DataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      { name: 'title', children: '特别鸣谢', className: 'title-h1' },
    ],
  },
  block: {
    className: 'img-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/TFicUVisNHTOEeMYXuQF.svg',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/hkLGkrlCEkGZeMQlnEkD.svg',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/bqyPRSZmhvrsfJrBvASi.svg',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/UcsyszzOabdCYDkoPPnM.svg',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/kRBeaICGexAmVjqBEqgw.svg',
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/ftBIiyJcCHpHEioRvPsV.svg',
          },
        },
      },
    ],
  },
};
