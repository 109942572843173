import React from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { isImg } from './utils';
import Globe3D from '../components/globe3D';

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    const { isMobile } = this.props; // you can set your own mobile width criteria

    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
          <div key="canvasRef">
            <Globe3D key="globe" isMobile={this.props.isMobile} />
          </div>
          <div key="title" {...dataSource.title} className="title" style={{marginTop: '50px', fontSize: '4em', color: 'white' , fontFamily: 'Arial' }}>
            {this.props.isMobile ? (
              "WorldwideData"
            ) : (
              typeof dataSource.title.children === 'string' &&
                dataSource.title.children.match(isImg) ? (
                <img src={dataSource.title.children} width="100%" alt="img" />
              ) : (
                dataSource.title.children
              )
            )}
          </div>
          <div key="content" {...dataSource.content}>
            {dataSource.content.children}
          </div>
          {dataSource.button && (
            <Button
              ghost
              key="button"
              {...dataSource.button}
              className="banner0-button"
            >
              {dataSource.button.children}
            </Button>
          )}
        </QueueAnim>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </div>
    );
  }
}


export default Banner;
