/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

// import Nav0 from './Nav0';
import Banner0 from './Banner0';
import Content9 from './Content9';
import Content0 from './Content0';
import Content5 from '../ServicesHome/Content5';
import Teams1 from './Teams1';

// import Footer1 from './Footer1';
// import NotFound from '../NotFound';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route
// } from "react-router-dom";

// import Footer1 from './Footer1';
// import ServicesHome from '../ServicesHome';
// //import NotFound from '../NotFound';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route
// } from "react-router-dom";


// >>>>>>> master

// Content30DataSource,
import {
  Banner01DataSource,
  Content90DataSource,
  Content00DataSource,
  Teams10DataSource,
} from './data.source';
import './less/antMotionStyle.less';

import {Content50DataSource} from "../ServicesHome/data.source"

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    document.title = "Homepage";
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    
    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
  }

  render() {
    const Homechildren = [
      // <Nav0
      //   id="Nav0_0"
      //   key="Nav0_0"
      //   dataSource={Nav00DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Banner0
        id="Banner0_1"
        key="Banner0_1"
        dataSource={Banner01DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content9
        id="Content9_0"
        key="Content9_0"
        dataSource={Content90DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content0
        id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}
      />,

      <Content5
        id="Content5_0"
        key="Content5_0"
        dataSource={Content50DataSource}

        isMobile={this.state.isMobile}
      />,
      <Teams1
        id="Teams1_0"
        key="Teams1_0"
        dataSource={Teams10DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Footer1
      //   id="Footer1_0"
      //   key="Footer1_0"
      //   dataSource={Footer10DataSource}
      //   isMobile={this.state.isMobile}
      // />,
    ];
    return (
        <div
          className="templates-wrapper"
          ref={(d) => {
            this.dom = d;
          }}
        >
          {/* <Routes>
            <Route exact path="/" component={Home} />
            <Route exact path="/services" component={ServicesHome} />
          </Routes> */}
          {}
          {this.state.show && Homechildren}
          {}
        </div>
    );
  }
}
