import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
// import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import ThreeGlobe from 'three-globe';

const Globe3D = () => {
  const globeContainer = useRef();

  useEffect(() => {
    const container = globeContainer.current;
    const width = container.offsetWidth;
    const height = container.offsetHeight;

    // Gen random data
    const N = 20;
    const arcsData = [...Array(N).keys()].map(() => ({
      startLat: (Math.random() - 0.5) * 180,
      startLng: (Math.random() - 0.5) * 360,
      endLat: (Math.random() - 0.5) * 180,
      endLng: (Math.random() - 0.5) * 360,
      color: ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)]
    }));

    const Globe = new ThreeGlobe()
      .globeImageUrl('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg')
      .arcsData(arcsData)
      .arcColor('color')
      .arcDashLength(0.4)
      .arcDashGap(4)
      .arcDashInitialGap(() => Math.random() * 5)
      .arcDashAnimateTime(1000);

    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(width, height);
    container.appendChild(renderer.domElement);

    const scene = new THREE.Scene();
    scene.add(Globe);
    scene.add(new THREE.AmbientLight(0xffffff, 0.8));
    const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
    directionalLight.position.set(0, 0, 3);
    directionalLight.castShadow = true;
    directionalLight.shadow.bias = -0.003;
    directionalLight.shadow.mapSize.width = 2048;
    directionalLight.shadow.mapSize.height = 2048;
    directionalLight.shadow.camera.left = -2;
    directionalLight.shadow.camera.right = 2;
    directionalLight.shadow.camera.top = -2;
    directionalLight.shadow.camera.bottom = 2;
    directionalLight.shadow.camera.near = 1;
    directionalLight.shadow.camera.far = 5;
    scene.add(directionalLight);

    const camera = new THREE.PerspectiveCamera();
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    camera.position.z = 300;
 // Initialize controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.minDistance = 300;
    controls.maxDistance = 400;
    controls.rotateSpeed = 0.7;
    controls.zoomSpeed = 0.8;
    controls.enableZoom = false; // Disable zoom
    controls.enablePan = true; // Enable panning
    controls.minPolarAngle = Math.PI / 3.5;
    controls.maxPolarAngle = Math.PI - Math.PI / 100;

    // const tbControls = new TrackballControls(camera, renderer.domElement);
    // tbControls.minDistance = 101;
    // tbControls.rotateSpeed = 10;
    // tbControls.zoomSpeed = 0;
    // tbControls.noPan = true;

    function animate() {
      controls.update();
      Globe.rotateY(0.001); // Rotate the globe
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    }
    animate();

    return () => {
      renderer.dispose();
    };
  }, []);

  return <div ref={globeContainer} style={{ width: '100%', height: '100%' }} />;
};

export default Globe3D;
