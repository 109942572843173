import React, { useState } from 'react';
import { Button, Drawer } from 'antd';

const CookieConsentBanner = () => {
  const [visible, setVisible] = useState(!localStorage.getItem('cookieConsent'));

  const onClose  = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setVisible(false);
    // Add additional logic to initialize tracking scripts here
  };

  const onAccept  = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setVisible(false);
  };

  return (
    <Drawer
      title="This website uses cookies"
      placement="bottom"
      closable={false}
      onClose={onClose}
      visible={visible}
      height={200} // Adjust this value as needed
      bodyStyle={{ padding: '10px' }} // Adjust padding as needed
    >
      <p>We use cookies to ensure that we give you the best experience on our website. If you continue without changing your settings, we'll assume that you are happy to receive all cookies on this website.</p>
      <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: '10px' }} onClick={onClose}>Decline</Button>
        <Button type="primary" onClick={onAccept}>Accept</Button>
      </div>
    </Drawer>
  );
};

export default CookieConsentBanner;
