import React from 'react';
// import assets from '../data/Assets/3D/project-macas/'

const workds3DHomePic = new URL('../images/works3D/digital-landscape-home.jpg', import.meta.url);

export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper ligmuqnq4zq-editor_css',
    imgage: workds3DHomePic,
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:'',
        className: 'title-image',
        style: { color: '#000000', fontSize: '32px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
      { 
        name: 'title', 
        children: 'Unleashing the Potential of Drone Technology', 
        className: 'title-h1',
        style: { color: '#000000', fontSize: '35px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' }, 
      },
      {
        name: 'content',
        children:'Transforming Construction Site Visualization: Embrace the Power of Immersive 3D Models',
        className: 'title-content',
        style: { color: '#000000', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
      {
        name: 'content2',
        children: '',
        className: 'title-content',
      },
    ],
  },
};
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  glbUrl: '../../public/project-macas/odm_textured_model_geo.glb',
  pngUrls: [
    './3D/project-macas/odm_textured_model_geo_material0000_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0001_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0002_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0003_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0004_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0005_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0006_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0007_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0008_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0009_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0010_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0011_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0012_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0013_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0014_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0015_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0016_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0017_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0018_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0019_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0020_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0021_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0022_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0023_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0024_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0025_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0026_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0027_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0028_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0029_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0030_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0031_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0032_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0033_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0034_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0035_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0036_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0037_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0038_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0039_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0040_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0041_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0042_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0043_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0044_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0045_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0046_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0047_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0048_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0049_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0050_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0051_map_Kd.png',
    './3D/project-macas/odm_textured_model_geo_material0052_map_Kd.png',
  ],
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content',
        children: '',
      },
    ],
  },
  // video: {
  //   className: 'content4-video',
  //   children: {
  //     video: 'https://os.alipayobjects.com/rmsportal/EejaUGsyExkXyXr.mp4',
  //     image: 'https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg',
  //   },
  // },
};
// export const Content41DataSource = {
//   wrapper: { className: 'home-page-wrapper content4-wrapper' },
//   page: { className: 'home-page content4' },
//   OverPack: { playScale: 0.3, className: '' },
//   glbUrl:'../../public/project-macas/odm_textured_model_geo.glb',
//   pngUrls: [
//     './3D/project-macas/odm_textured_model_geo_material0000_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0001_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0002_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0003_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0004_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0005_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0006_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0007_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0008_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0009_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0010_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0011_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0012_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0013_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0014_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0015_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0016_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0017_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0018_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0019_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0020_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0021_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0022_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0023_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0024_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0025_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0026_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0027_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0028_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0029_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0030_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0031_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0032_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0033_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0034_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0035_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0036_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0037_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0038_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0039_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0040_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0041_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0042_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0043_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0044_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0045_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0046_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0047_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0048_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0049_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0050_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0051_map_Kd.png',
//     './3D/project-macas/odm_textured_model_geo_material0052_map_Kd.png',
//   ],
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [
//       {
//         name: 'title',
//         children: '蚂蚁金融云提供专业的服务',
//         className: 'title-h1',
//       },
//       {
//         name: 'content',
//         className: 'title-content content4-title-content',
//         children: '科技想象力，金融创造力',
//       },
//     ],
//   },
//   // video: {
//   //   className: 'content4-video',
//   //   children: {
//   //     video: 'https://os.alipayobjects.com/rmsportal/EejaUGsyExkXyXr.mp4',
//   //     image: 'https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg',
//   //   },
//   // },
// };
