import React from 'react';

import JoaoMCarvalho from './images/JoaoMCarvalho.jpeg';
import afonso from './images/afonso-ceo-2.jpeg'
import francisco from './images/francisco.png'
import light from './images/light-bulb.png'
import development from './images/app-development.png'
import funding from './images/profit.png'
import product from './images/box.png'
import check from './images/check.png'
import uncheck from './images/uncheck.png'
import ongoing from './images/refresh.png'
import robotic from './images/robotic-hand.png'
import decision from './images/decision-making.png'
import world from './images/world.png'
import { Light } from 'three';
import W2D_logo_SVG from '../data/Worldwidedata-textOnly-white.png';
import w2d_logo_white from '../data/Worldwidedata-log-white.png';
import constructionIcon from './images/icons/construction-icon.png';



export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: w2d_logo_white,
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '/',
          children: [{ children: 'Home', name: 'text' }],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '/services',
          children: [{ children: 'Services', name: 'text' }],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              // href: '/mapeamento3D', // add href property here
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  href: '/services/construction', // add href property here
                  children: 'Construction',
                },
              ],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              // href: '/mapeamento3D', // add href property here
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  href: '/services/agriculture',
                  // href: '/mapeamento-3d', // add href property here
                  children: 'Agriculture',
                },
              ],
            },
          },
          {
            name: 'sub2',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              // href: '/mapeamento3D', // add href property here
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  // href: '/mapeamento-3d', // add href property 
                  href: '/services/territorial',
                  children: 'Territorial',
                },
              ],
            },
          },
          {
            name: 'sub3',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              // href: '/mapeamento3D', // add href property here
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  // href: '/mapeamento-3d', // add href property here
                  href: '/services/ai-models',
                  children: 'AI-Models',
                },
              ],
            },
          },
          {
            name: 'sub4',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              // href: '/mapeamento3D', // add href property here
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  // href: '/mapeamento-3d', // add href property here
                  href: '/services/real-state',
                  children: 'Real-State',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '/work',
          children: [{ children: 'Work', name: 'text' }],
        },
        subItem: [
          {
            name: 'sub5',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              // href: '/mapeamento3D', // add href property here
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  href: '/3d-mapping', // add href property here
                  children: 'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  // href: '/mapeamento-3d', // add href property here
                  children: '3D Mapping',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  // href: '/mapeamento-3d', // add href property here
                  children: 'Lista de projectos',
                },
              ],
            },
          },
          // {
          //   name: 'sub1',
          //   className: 'item-sub',
          //   children: {
          //     className: 'item-sub-item',
          //     children: [
          //       {
          //         name: 'image0',
          //         className: 'item-image',
          //         children:
          //           'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
          //       },
          //       {
          //         name: 'title',
          //         className: 'item-title',
          //         children: 'Ant Design',
          //       },
          //       {
          //         name: 'content',
          //         className: 'item-content',
          //         children: '企业级 UI 设计体系',
          //       },
          //     ],
          //   },
          // },
        ],
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '/about',
          children: [{ children: 'About', name: 'text' }],
        },
      },

    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <img src={W2D_logo_SVG} alt="img" style={{ width: '50%', height: '30%' }} />
    </div>
  },
  content: {
    className: 'banner0-content',
    children: '',
  },
  button: { className: 'banner0-button', children: 'Learn More', href:'#roadMap' },
};
export const Content90DataSource = {
  wrapper: { className: 'home-page-wrapper content9-wrapper' },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          '',
        className: 'title-image',
      },
      { name: 'title', children: 'Road Map', className: 'title-h1' , id:'roadMap'},
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block0',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              light,
          },
          icon: {
            className: 'block-icon',
            children:
              check,
          },
          name: { className: 'block-name', children: 'The ideia' },
          post: { className: 'block-post', children: '' },
          time: { className: 'block-time', children: 'Feb 2023 - Mar 2023' },
          title: { className: 'block-title', children: 'Starting point' },
          content: { className: 'block-content', children: 'Emergence of the idea to expand the power of data and artificial intelligence across industries, to all parts of the world, and help foster its development.' },
        },
      },
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              development,
          },
          icon: {
            className: 'block-icon',
            children:
              check,
          },
          name: { className: 'block-name', children: 'Prototype' },
          post: { className: 'block-post', children: '' },
          time: { className: 'block-time', children: 'Mar 2023 - Jun 2023' },
          title: { className: 'block-title', children: 'Second stage' },
          content: {
            className: 'block-content',
            children:
              'First prototype of 3D modeling for structures and terrains, and development of artificial intelligence algorithms for wildlife identification.',
          },
        },
      },
      {
        name: 'block2',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              funding,
          },
          icon: {
            className: 'block-icon',
            children:
              ongoing,
          },
          name: { className: 'block-name', children: 'Founding' },
          post: { className: 'block-post', children: '' },
          time: { className: 'block-time', children: 'Jul 2023 - Jan 2024' },
          title: { className: 'block-title', children: 'Third stage' },
          content: {
            className: 'block-content',
            children:
              'Searching for clients for the various use cases we have developed and investors.',
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              product,
          },
          icon: {
            className: 'block-icon',
            children:
              uncheck,
          },
          name: { className: 'block-name', children: 'Company expansion' },
          post: { className: 'block-post', children: '' },
          time: { className: 'block-time', children: '2024' },
          title: { className: 'block-title', children: 'Final product' },
          content: {
            className: 'block-content',
            children:
              '"All-in-the-box" solution, featuring fully autonomous drones capable of performing their operations entirely automatically with real-time diagnostics.',
          },
        },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Our vision' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                <img src={robotic} width="120%" alt="img" />,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Philanthropy Driven',
            },
            { name: 'content', children: 'We aim to offer services that bring the power of data and high technology to everyone, all around the world.' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                <img src={decision} width="120%" alt="img" />,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Decision-making',
            },
            {
              name: 'content',
              children: 'leveraging advanced technologies like drone imagery and sensor data to deliver innovative decision-making tools.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                <img src={world} width="120%" alt="img" />,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Connecting the world',
            },
            {
              name: 'content',
              children: 'Through automatic data collection, everyone will be able to remotely monitor their businesses.',
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '企业资源管理' },
          content: {
            className: 'content3-content',
            children:
              '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云安全' },
          content: {
            className: 'content3-content',
            children:
              '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children:
              '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '移动' },
          content: {
            className: 'content3-content',
            children:
              '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '分布式中间件' },
          content: {
            className: 'content3-content',
            children:
              '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:
              '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },
    ],
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Our team' }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                <img src={francisco} width="100%" alt="img" />,
            },
            { name: 'title', className: 'teams1-title', children: 'Francisco Albuquerque' },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Co-Founder',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Electrical and computer engineer with experience in AI utilization for drones',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                <img src={afonso} width="100%" alt="img" />,
            },
            { name: 'title', className: 'teams1-title', children: 'Afonso Neto' },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'CEO',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Software Engineer focused on cloud development and AI, with vast knowleged in distributed systems',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                <img src={JoaoMCarvalho} width="100%" alt="img" />
              ,
            },
            { name: 'title', className: 'teams1-title', children: 'João Carvalho' },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Co-Founder',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Electrical engineer with experience in research on autonomous aerial vehicles',
            },
          ],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            <img src={w2d_logo_white} width="120%" alt="img" />,
        },
        childWrapper: {
          className: 'slogan2',
          children: [
            {
              name: 'content0',
              children: 'WorldWideData, Your Guide in the Data Tide.',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Contact' },
        childWrapper: {
          children: [
            { name: 'link0', href: '#', children: 'info@worldwidedata.pt' },
            { name: 'link1', href: '#', children: '(351)916763040' },
            { name: 'link2', href: '#', children: '(351)914216564' },

          ],
        },
      },
      /*  {
         name: 'block2',
         xs: 24,
         md: 6,
         className: 'block',
         title: { children: 'About' },
         childWrapper: {
           children: [
             //{ href: '#', name: 'link0', children: 'FAQ' },
             { href: '/about', name: 'link1', children: 'about us' },
           ],
         },
       }, */
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Site' },
        childWrapper: {
          children: [
            { href: '#', name: 'link0', children: 'Home' },
            { href: '/services', name: 'link1', children: 'Services' },
            { href: '/about', name: 'link1', children: 'About' },
            { href: '/mapeamento-3d', name: 'link1', children: 'Work' },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2023 by <a href="https://worldwidedata.pt">Worldwidedata</a> All Rights
        Reserved
      </span>
    ),
  },
};
