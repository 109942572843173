import React from 'react';
import TweenOne from 'rc-tween-one';
import { Menu } from 'antd';
import { getChildrenToRender } from './utils';
import { Link } from "react-router-dom";

const { Item, SubMenu } = Menu;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.menuRef = React.createRef();  // Create a ref
    this.state = {
      phoneOpen: false,
      height: 0,  // Initialize height to 0
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // If phoneOpen state has changed, update the height
    if (this.state.phoneOpen !== prevState.phoneOpen) {
      console.log('phoneOpen state has changed = ' + this.state.phoneOpen);
      this.setState({
        // height: !this.state.phoneOpen ? 'auto' : '0' ,
      }, () => {
        console.log(`Updated height to: ${this.state.height}`);
      });
    }
  }

  phoneClick = () => {
    this.setState(prevState => ({
      phoneOpen: !prevState.phoneOpen,
      height: !prevState.phoneOpen ? 'auto' : '0', // Update the height value
    }), () => {
      console.log(`Updated phoneOpen to: ${this.state.phoneOpen}`);
      console.log(`Updated height to: ${this.state.height}`);
    });
  };


  render() {
    const { dataSource, ...props } = this.props;
    const { phoneOpen } = this.state;
    const { isMobile } = this.props;
    const navData = dataSource.Menu.children
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div
                {...a}
                className={`header0-item-block ${a.className}`.trim()}
              >
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header0-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <Link {...childItem} to={childItem.href}>
                  {childItem.children.map(getChildrenToRender)}
                </Link>
              ) : (
                <div {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <a {...a} className={`header0-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </a>
        </Item>
      );
    });
    // const moment = phoneOpen === false ? 300 : null;
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 1, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: 30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
          >
            <img
              src={dataSource.logo.children}
              alt="img"
              style={{ width: '250px', height: 'auto' }}
            />
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={this.phoneClick}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                  height: this.state.phoneOpen ? 'auto' : this.state.height,  // Use the state's height value here
                  duration: 200,
                  ease: 'easeInOutQuad',
                }
                : null
            }
            reverse={!!phoneOpen}
            style={{ height: this.state.phoneOpen ? 'auto' : this.state.height }}
            ref={this.menuRef}  // Assign the ref here
          >
            <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              theme="dark"
              selectedKeys={this.state.selectedKeys}
              onClick={({ key }) => {
                this.setState({ selectedKeys: [key] });
              }}
            >
              {navChildren}
            </Menu>
          </TweenOne>
        </div>
      </TweenOne>
    );
  }
}

export default Header;