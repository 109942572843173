import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { enquireScreen } from 'enquire-js';
import Header from './Home/Nav0';
import Footer from './Home/Footer1';

import Home from './Home';
import ServicesHome from './ServicesHome';
import AboutHome from './AboutHome'
import Works3D from './Works3D'
import ConstructionHome from './ProductPages/Construction'
import AgricultureHome from './ProductPages/Agriculture'
import AIModelsHome from './ProductPages/AI_Models'
import MonumentPreservationHome from './ProductPages/MonumentPreservation'
import RealStateHome from './ProductPages/RealState'
import TerritorialHome from './ProductPages/Territorial'
// import logo from "./Worldwidedata-logo.png"
import RouteChangeTracker from '../src/components/RouteChangeTracker.jsx'
import { Footer10DataSource } from './Home/data.source.js';
import { Nav00DataSource } from './Home/data.source.js';

import CookieConsentBanner from '../src/components/cookieBanner';
// const TRACKING_ID = "G-9WN3DWWY7N"; // YOUR_OWN_TRACKING_ID

// ReactGA.initialize(
//   {
//     trackingId: TRACKING_ID ,
//     gtagOptions: {debug: true} ,
//   });
// ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Page Visits" })

const { location = {} } = typeof window !== 'undefined' ? window : {};

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }

  componentDidMount() {
    // document.title = "WorldWideData";
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
      // Support for mobile resolutions
      if (location.port) {
        setTimeout(() => {
          this.setState({
            show: true,
          });
        }, 500);
      }
    });
  }

  render() {
    return (
      <Router>
        <div>
          <header>
            <Header id="Nav0_0" key="Nav0_0" dataSource={Nav00DataSource} isMobile={this.state.isMobile} />
          </header>
          <RouteChangeTracker />
          <CookieConsentBanner />
          <Routes>
            {/* isMobile={this.isMobile} */}
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<ServicesHome />} />
            <Route path="/about" element={<AboutHome />} />
            <Route path="/3d-mapping" element={<Works3D />} />
            <Route path="/services/construction" element={<ConstructionHome />} />
            <Route path="/services/agriculture" element={<AgricultureHome />} />
            <Route path="/services/monument-preservation" element={<MonumentPreservationHome />} />
            <Route path="/services/ai-models" element={<AIModelsHome />} />
            <Route path="/services/real-state" element={<RealStateHome />} />
            <Route path="/services/territorial" element={<TerritorialHome />} />
          </Routes>
          <Footer
            id="Footer1_0"
            key="Footer1_0"
            dataSource={Footer10DataSource}
            isMobile={this.state.isMobile}
          />
        </div>
      </Router>
    );
  }
}

export default App;
