import React from 'react';

const MonumentPreservationHome = new URL('../../images/monuments/MonumentPreservationHome.jpg', import.meta.url);
const MonumentPreservationVirtual = new URL('../../images/monuments/virtual-tourism.jpg', import.meta.url);
const MonumentPreservationPic = new URL('../../images/monuments/monument-preservation.jpg', import.meta.url);
const MonumentPreservationTimeTravel = new URL('../../images/monuments/time-travel-monument.jpg', import.meta.url);
const MonumentPreservationPast = new URL('../../images/monuments/echo-of-the-past.jpg', import.meta.url);




export const Banner30DataSource = {
  wrapper: { className: 'banner3 lihrajp3ekm-editor_css' },
  img: { children: MonumentPreservationHome },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: 'Reviving the past, preserving for the future',
        style: { color: '#000000', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '首届蚂蚁金服体验科技大会', // needs to stay like this to stay center (dont know why)
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: 'Bringing History Back to Life with 3D & AI',
        style: { color: '#000000', fontSize: '32px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
      // { name: 'button', className: 'banner3-button', children: 'See Our Solutions' },
      {
        name: 'time',
        className: 'banner3-time',
        children: 'Exploring collaborations with leading heritage organizations',
        style: { color: '#000000', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: MonumentPreservationVirtual,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Step Inside 3D History' },
  content: {
    className: 'content1-content',
    children:
      "Step into the past and experience history like never before with our intricate 3D mappings of historical sites. Using our advanced technology, we create immersive and breathtaking 3D models that allow you to virtually explore and interact with historical monuments. Walk through ancient halls, marvel at architectural details, and gain a deeper understanding of the rich heritage preserved within these sites. Our 3D mappings offer a unique opportunity to delve into history, making it come alive with stunning realism and historical accuracy.",
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: MonumentPreservationPic,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'Heritage Visionaries' },
  content: {
    className: 'content2-content',
    children:
    "As heritage visionaries, we are dedicated to preserving and recreating the glory of historical sites with meticulous accuracy and profound respect for the past. Our team of experts combines cutting-edge technology with extensive research to recreate historical monuments with unparalleled authenticity. Every architectural detail, texture, and ornamentation is carefully crafted to capture the essence of the original structure. By partnering with us, you can ensure that the legacy of these significant sites is faithfully preserved and can be appreciated by future generations." },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: MonumentPreservationTimeTravel,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Time Travel Is Here' },
  content: {
    className: 'content1-content',
    children:
      "Transport yourself through the annals of time and immerse yourself in the atmosphere of past eras with our groundbreaking technology. Our innovative solutions allow you to experience historical sites in a way that was previously unimaginable. From ancient civilizations to pivotal moments in history, our technology bridges the gap between the past and the present. Step into the shoes of our ancestors, witness historical events unfold, and gain a profound appreciation for the cultural and historical significance of these sites. With our time-travel-like experience, history is no longer confined to textbooks but becomes a vivid and unforgettable journey.",
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: MonumentPreservationPast,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'Echoes of the Past' },
  content: {
    className: 'content2-content',
    children:
      "Uncover the echoes of the past as our sophisticated drone technology meticulously captures unseen corners and illuminates the hidden stories of historical sites. With a keen eye for detail, our drones capture high-resolution imagery, unveiling intricate architectural features and preserving delicate elements that may otherwise go unnoticed. Through our meticulous documentation, we bring these forgotten corners to life, rekindling memories and reviving the past in stunning detail. Our technology allows us to preserve the essence of historical monuments, ensuring that their significance endures for generations to come. Experience the wonders of monument preservation with our innovative solutions. Step inside 3D history, witness the vision of heritage preservation, embark on a time-traveling adventure, and uncover the echoes of the past with our groundbreaking drone technology. ",
  },
};
// export const Content90DataSource = {
//   wrapper: { className: 'home-page-wrapper content9-wrapper' },
//   page: { className: 'home-page content9' },
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [
//       {
//         name: 'image',
//         children:
//           'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
//         className: 'title-image',
//       },
//       { name: 'title', children: '会议日程', className: 'title-h1' },
//     ],
//   },
//   block: {
//     className: 'timeline',
//     children: [
//       {
//         name: 'block0',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '开幕致辞' },
//           content: { className: 'block-content', children: '' },
//         },
//       },
//       {
//         name: 'block1',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//       {
//         name: 'block2',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//       {
//         name: 'block3',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//     ],
//   },
// };
// export const Content120DataSource = {
//   wrapper: { className: 'home-page-wrapper content12-wrapper' },
//   page: { className: 'home-page content12' },
//   OverPack: { playScale: 0.3, className: '' },
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [
//       {
//         name: 'image',
//         children:
//           'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
//         className: 'title-image',
//       },
//       { name: 'title', children: '特别鸣谢', className: 'title-h1' },
//     ],
//   },
//   block: {
//     className: 'img-wrapper',
//     children: [
//       {
//         name: 'block0',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/TFicUVisNHTOEeMYXuQF.svg',
//           },
//         },
//       },
//       {
//         name: 'block1',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/hkLGkrlCEkGZeMQlnEkD.svg',
//           },
//         },
//       },
//       {
//         name: 'block2',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/bqyPRSZmhvrsfJrBvASi.svg',
//           },
//         },
//       },
//       {
//         name: 'block3',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/UcsyszzOabdCYDkoPPnM.svg',
//           },
//         },
//       },
//       {
//         name: 'block4',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/kRBeaICGexAmVjqBEqgw.svg',
//           },
//         },
//       },
//       {
//         name: 'block5',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/ftBIiyJcCHpHEioRvPsV.svg',
//           },
//         },
//       },
//     ],
//   },
// };
