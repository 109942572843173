import React from 'react';
import machineLearning from './data/deep-learning.png'
import consulting from './data/consulting.png'
import data from './data/data-analysis.png'
import costumer from './data/customer-service.png'
import junta from './data/junta(2).jpg'
import vectorImg from './data/vecteezy_best-cloud-server-consultation-for-storing-data_8258234.jpg'


export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  textWrapper: { className: 'content2-text', md: 14, xs: 30 },
  title: { className: 'content2-title', children: 'Word Wide Data specializes in cutting-edge data collection and processing methods. We leverage advanced technologies such as drone imagery, sensor data, and more to deliver innovative decision-making tools that drive efficiency and enable businesses to stay ahead of the curve in todays rapidly evolving landscape.' },
  content: {
    className: 'content2-content',
    children:
      '',
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'Empowering Businesses with Data-driven Solutions',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: 'Unleashing the Potential of Data for Success and Innovation',
      },
    ],
  },
  img: {
    children: vectorImg,
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            machineLearning,
          className: 'content6-icon',
          style: {
            width: '40px',
            height: '40px',
          },
        },
        title: { className: 'content6-title', children: 'Developing machine learning models' },
        content: {
          className: 'content6-content',
          children:
            'We can develop sophisticated models that enable efficient and accurate processing of data.',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            data,
            style: {
              width: '40px',
              height: '40px',
            },
        },
        
        title: { className: 'content6-title', children: 'Data Analysis' },
        content: {
          className: 'content6-content',
          children:
            'We can analyze data to provide valuable insights and intelligence to its clients, helping them to make informed decisions and improve operational efficiency.',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            costumer,
            data,
            style: {
              width: '40px',
              height: '40px',
            },
        },
        title: { className: 'content6-title', children: 'Providing Services' },
        content: {
          className: 'content6-content',
          children:
            'Our company offers a range of services, including aerial mapping, infrastructure inspection, environmental monitoring, agriculture analytics, and construction monitoring',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            consulting,
             data,
            style: {
              width: '40px',
              height: '40px',
            },
        },
        title: { className: 'content6-title', children: 'Consulting' },
        content: {
          className: 'content6-content',
          children:
            'Our company can provide consulting services to its clients, offering expert advice on how to best utilize data to achieve their goals.',
        },
      },
    ],
  },
};
export const Content120DataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        
        className: 'title-image',
      },
      { name: 'title', children: 'Clients', className: 'title-h1' },
    ],
  },
  block: {
    className: 'img-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              junta,
              style: {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              },
              
          },
        },
      },
 /*      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/hkLGkrlCEkGZeMQlnEkD.svg',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/bqyPRSZmhvrsfJrBvASi.svg',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/UcsyszzOabdCYDkoPPnM.svg',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/kRBeaICGexAmVjqBEqgw.svg',
          },
        },
      }, */
      /* {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/ftBIiyJcCHpHEioRvPsV.svg',
          },
        },
      }, */
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper ligeo8jpqp-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
          
        className: 'title-image',
      },
      { name: 'title', children: '丰富的特色展台', className: 'title-h1' },
      {
        name: 'content',
        children:
          '特色展台包括 Ant Design 、AntV、AntG、Egg 等明星产品，更有产品专家',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: '现场问诊，为你答疑解难',
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: { a: { className: 'button', href: '#', children: '立即报名' } },
  },
};
