import React, { useRef, useEffect } from 'react';
import { Vector3, WebGLRenderer, Scene, AmbientLight, PerspectiveCamera, Color, Fog, DirectionalLight, PointLight, SphereGeometry, WireframeGeometry } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import ThreeGlobe from 'three-globe';
import countries from '../data/globe-data-min.json';

export default function Globe3D({ isMobile }) {
  console.log('Is Mobile:', isMobile); // Add this line
  const canvasRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const renderer = new WebGLRenderer({ canvas: canvasRef.current });
    const scene = new Scene();
    const camera = new PerspectiveCamera();
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    // Initial Renderer
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    // Initial scene, light
    scene.add(new AmbientLight(0xbbbbbb, 0.3));
    scene.background = new Color(0x040d21);

    // Initial camera, light
    var dLight = new DirectionalLight(0xffffff, 0.8);
    dLight.position.set(-800, 2000, 400);
    var dLight1 = new DirectionalLight(0x7982f6, 0.7);
    dLight1.position.set(-200, 500, 200);
    camera.add(dLight1);
    var dLight2 = new PointLight(0x8566cc, 0.9);
    dLight2.position.set(-200, 100, 200);
    camera.add(dLight2);
    // Add Globe
    const globe = new ThreeGlobe()
      .hexPolygonsData(countries.features)
      .hexPolygonResolution(3)
      .hexPolygonMargin(0.1)
      .showAtmosphere(true)
      .atmosphereColor("#3a228a")
      .atmosphereAltitude(0.3)
      .hexPolygonColor((e) => {
        if (["PRT"].includes(e.properties.ISO_A3)) {
          return "rgba(0,0,0, 1)";
        } else if (["1. Developed region: G7"].includes(e.properties.ECONOMY)) {
          return "rgba(207, 234, 31, 1)";
        } else {
          return "rgba(207, 234, 131, 1)";
        }
      });

    // Adjust camera position and globe size based on environment
    if (isMobile) {
      camera.position.z = 500; // Adjust the z-position for mobile
      globe.scale.set(0.55, 0.55, 0.55); // Reduce globe size for mobile
    } else {
      camera.position.z = 1000; // updated
      camera.position.x = 0;
      camera.position.y = 0;
    }


    scene.add(camera);

    // Additional effects
    scene.fog = new Fog(0x535ef3, 400, 2000);

    // Initialize controls
    const controls = new OrbitControls(camera, renderer.domElement);
    if (isMobile) {
      controls.minDistance = 200; // Adjust these values as needed
      controls.maxDistance = 300;
      controls.enableZoom = false;
      controls.enablePan = false;
      controls.minPolarAngle = Math.PI / 3;
      controls.maxPolarAngle = Math.PI - Math.PI / 5;
    } else {
      controls.minDistance = 300;
      controls.maxDistance = 400;
      controls.rotateSpeed = 0.7;
      controls.zoomSpeed = 0.8;
      controls.enableZoom = false;
      controls.enablePan = false;
      controls.minPolarAngle = Math.PI / 3.5;
      controls.maxPolarAngle = Math.PI - Math.PI / 100;
    }

    window.addEventListener("resize", onWindowResize, false);



    globe.rotateY(Math.PI * 1.9);
    globe.rotateZ(-Math.PI / 10.1);
    const globeMaterial = globe.globeMaterial();
    globeMaterial.color = new Color("#234f61");
    globeMaterial.emissiveIntensity = 0.9;
    globeMaterial.shininess = 0.1;
    const geometry = new SphereGeometry(100, 30, 30);
    const wireframe = new WireframeGeometry(geometry);
    globeMaterial.wireframe = true;

    scene.add(globe);

    // Animation
    const animate = () => {
      controls.update();
      renderer.render(scene, camera);
      globe.rotateY(0.001);
      requestAnimationFrame(animate);
    };

    animate();

    // Clean up on component unmount
    return () => {
      renderer.dispose();
    };

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }

  }, []);

  // Style for overlay divs
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '25%',
    background: 'white' // change it to 'white' or any color for testing
  };

  return (
    <div style={{ position: 'relative' }}>
      <canvas ref={canvasRef} style={{ zIndex: 1 ,position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)' }} />
      {isMobile && (
        <>
          <div style={{ ...overlayStyle, left: 0 }}></div>
          <div style={{ ...overlayStyle, right: 0 }}></div>
        </>
      )}
    </div>
  );
}
