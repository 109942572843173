import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { MapControls } from 'three/addons/controls/MapControls.js';
//import { OffsetOrbitControls } from "./OffsetOrbitControls.js"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'


// import dataSource from 
// import DRACODecoder from 'three/';

// const parcelPath = new URL('../../public/project-macas/odm_textured_model_geo.glb', import.meta.url);
// const parcelTexturePath = new URL('../../public/project-macas)' , import.meta.url);

const Object3DViewer = ({ dataSource, width, height }) => {
  const containerRef = useRef();

  useEffect(() => {
    let scene, camera, renderer, controls;

    const init = () => {
      // Create a scene
      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xffffff); // Set the background color to white      
      // scene.background.set(0, 0, 0, 0); // Set the transparency of the background to 0 (fully transparent)
      scene.fog = new THREE.FogExp2(0xcccccc, 0.002);
      // Create a camera
      camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 300);
      // camera.position.set(70, 10, 0);
      // Calculate the bounding box of the loaded object
      
      // camera.position.set(0, 0, 0);
      // Create a renderer
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(width, height);
      renderer.setPixelRatio(window.devicePixelRatio);
      containerRef.current.appendChild(renderer.domElement);

      controls = new MapControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.screenSpacePanning = true; // this allows for map-like panning
      controls.minDistance = 50;
      controls.maxDistance = 200;
      controls.maxPolarAngle = Math.PI / 2;

      // controls.enableZoom = false; // Disable zoom
      controls.enablePan = true; // Enable panning
      controls.minPolarAngle = Math.PI / 3.5;
      controls.maxPolarAngle = Math.PI - Math.PI / 100;
      // controls.maxPolarAngle = Math.PI / 2;
      // Set the target of the MapControls to be the center of the bounding box
      // Add lights to the scene
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(1, 1, 1);
      scene.add(directionalLight);
      // Create a DRACOLoader instance
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

      // Create a GLTFLoader instance
      const loader = new GLTFLoader();
      loader.setDRACOLoader(dracoLoader); // Set the DRACOLoader instance on the GLTFLoader
      // console.log(dataSource.glbUrl)
      // Load the GLB file
      const parcelPath = new URL("../../public/project-macas/odm_textured_model_geo.glb", import.meta.url);
      const glbUrl = parcelPath.href;
      const fileNames = dataSource.pngUrls;
      const pngUrls = fileNames.map(fileName => {
        const texturePath = new URL(fileName, import.meta.url);
        return texturePath.href;
      });

      // // Load the GLB file
      // const pngUrls = dataSource.pngUrls;
      function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);

      }

      loader.load(glbUrl, (gltf) => {
        // Assign textures to materials
        // gltf.scene.traverse((child) => {
        //   if (child.isMesh) {
        //     const materialIndex = child.materialIndex;
        //     const textureUrl = pngUrls[materialIndex];
        //     const texture = new THREE.TextureLoader().load(textureUrl);
        //     child.material.map = texture;
        //   }
        // });

        // Calculate the bounding box of the loaded object
        const box = new THREE.Box3().setFromObject(gltf.scene);
        const size = box.getSize(new THREE.Vector3()).length();
        const center = box.getCenter(new THREE.Vector3());

        // Set the camera to frame the loaded object
        // camera.position.copy(center);
        camera.position.x = center.x;        
        camera.position.y = center.x; // Or some other value
        camera.position.z = 100;
        // Set the camera to frame the loaded object
        // camera.lookAt(center);

        // camera.position.copy(center);
        // camera.position.x += size / 10.0; // Adjust as needed

        // Set the target of the OrbitControls to be the center of the bounding box
        controls.target.copy(center);

        // Add the model to the scene
        scene.add(gltf.scene);

        // Animate the model
        animate();
      },
        // called while loading is progressing
        function (xhr) {
          console.log((xhr.loaded / xhr.total * 100) + '% loaded');
        },
        // called when loading has errors
        function (error) {
          console.log('An error happened');
          console.log(error);

        },
        undefined,
        (error) => {
          console.error('An error occurred while loading the GLTF file:', error);
        }
      );

    };

    const animate = () => {
      requestAnimationFrame(animate);
      // required if controls.enableDamping or controls.autoRotate are set to true
      controls.update();
      // required if controls.enableDamping or controls.autoRotate are set to true
      // camera.lookAt(controls.target);
      renderer.render(scene, camera);
    };

    // Initialize the scene
    init();
    // Assign the current ref value to a variable
    const element = containerRef.current;
    // Clean up on unmount
    return () => {
      renderer.dispose();
      controls.dispose();
      // Use the variable in the cleanup function
      if (element) {
        element.removeChild(renderer.domElement);
      }
    };
  }, [dataSource, width, height]);

  return <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />;
};

export default Object3DViewer;
