import React from 'react';
// import constructionHomePic from '../../images/construction-vector_orange.jpg'

const constructionHomePic = new URL('../../images/construction/construction-vector_orange.jpg', import.meta.url);
const monitorConstructionPic = new URL('../../images/construction/construction-monitor.jpg', import.meta.url);
const ResourcesConstructionPic = new URL('../../images/construction/resources-monitor-construction.jpg', import.meta.url);
const SustainableConstructionPic = new URL('../../images/construction/carbon_footprint-construction.jpg', import.meta.url);
const MistakesConstructionPic = new URL('../../images/construction/construction-mistakes.jpg', import.meta.url);



export const Banner30DataSource = {
  wrapper: { className: 'banner3 lihrajp3ekm-editor_css' },
  img: { children: constructionHomePic },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      // {
      //   name: 'nameEn',
      //   className: 'banner3-name-en',
      //   children: 'Seeking Partnerships & Engineering Conferences',
      // },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: 'Innovate, Automate, and Dominate with Our Drone Mapping Solution',
        texty: true,
        style: { color: '#000000', fontSize: '30px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: 'Embrace the Future of Construction Management Today',
        style: { color: '#000000', fontSize: '32px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
      // { name: 'button', className: 'banner3-button', children: '立即报名' },
      {
        name: 'time',
        className: 'banner3-time',
        children: '2023.06.4',
        style: { color: '#000000', fontSize: '32px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff' },
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: monitorConstructionPic,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Monitor Multiple Construction Sites Simultaneously' },
  content: {
    className: 'content1-content',
    children:
      'Elevate your construction management with our state-of-the-art drone mapping technology. Monitor multiple construction sites in real-time, create detailed 3D models, and ensure your projects are always on track. Revolutionize your workflow with seamless integration and dynamic scalability. Our drones capture precise data, enabling you to visualize the site from every angle. This helps in better decision-making and identifying potential issues before they escalate.',
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: ResourcesConstructionPic,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'Always Stay Resource-Ready' },
  content: {
    className: 'content2-content',
    children:
      "Never run out of critical resources. Utilize our intelligent drone system to monitor resource use and predict needs accurately. Ensure continuous workflow and achieve maximum efficiency. With our solution, you're always one step ahead. Our advanced technology enables you to monitor resource usage in real-time and accurately predict your future needs. ",
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: SustainableConstructionPic,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Build Sustainably, Build Responsibly' },
  content: {
    className: 'content1-content',
    children:
      "Gain a deeper understanding of the environmental impact of your construction projects. Reduce your carbon footprint with precision construction. Our technology provides data-driven insights to minimize waste and promote efficient resource use. With our system, you're not only building structures, you're building a sustainable future.",
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: MistakesConstructionPic,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'Detect Errors Before They Become Problems' },
  content: {
    className: 'content2-content',
    children:
      'Avoid costly mistakes and delays. Our technology allows for early error detection and provides valuable insights to prevent potential issues. Experience a new level of quality control and keep your projects on schedule, on budget, and up to your high standards. With our technology, you can ensure that your projects are always on track. Detect any deviations from the planned schedule or design, allowing you to take prompt action and keep your projects moving forward smoothly.',
  },
};

// export const Content90DataSource = {
//   wrapper: { className: 'home-page-wrapper content9-wrapper' },
//   page: { className: 'home-page content9' },
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [
//       {
//         name: 'image',
//         children:
//           'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
//         className: 'title-image',
//       },
//       { name: 'title', children: '会议日程', className: 'title-h1' },
//     ],
//   },
//   block: {
//     className: 'timeline',
//     children: [
//       {
//         name: 'block0',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '开幕致辞' },
//           content: { className: 'block-content', children: '' },
//         },
//       },
//       {
//         name: 'block1',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//       {
//         name: 'block2',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//       {
//         name: 'block3',
//         className: 'block-wrapper',
//         playScale: 0.3,
//         children: {
//           imgWrapper: { className: 'image-wrapper' },
//           textWrapper: { className: 'text-wrapper' },
//           img: {
//             className: 'block-img',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
//           },
//           icon: {
//             className: 'block-icon',
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
//           },
//           name: { className: 'block-name', children: '姓名' },
//           post: { className: 'block-post', children: '公司 职位' },
//           time: { className: 'block-time', children: '09:00 - 10:00' },
//           title: { className: 'block-title', children: '演示标题 - XYZ' },
//           content: {
//             className: 'block-content',
//             children:
//               '经过近 3 年的打磨，在助力中台产品研发效能提升的目标之上，包含设计语言、UI 资产、可视化以及产品体验相关的蚂蚁中台设计体系正在逐步成型。此次分享包含两部分，在介绍蚂蚁设计体系的同时，也会和大家分享我们在设计语言的部分探索。',
//           },
//         },
//       },
//     ],
//   },
// };
// export const Content120DataSource = {
//   wrapper: { className: 'home-page-wrapper content12-wrapper' },
//   page: { className: 'home-page content12' },
//   OverPack: { playScale: 0.3, className: '' },
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [
//       {
//         name: 'image',
//         children:
//           'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
//         className: 'title-image',
//       },
//       { name: 'title', children: '特别鸣谢', className: 'title-h1' },
//     ],
//   },
//   block: {
//     className: 'img-wrapper',
//     children: [
//       {
//         name: 'block0',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/TFicUVisNHTOEeMYXuQF.svg',
//           },
//         },
//       },
//       {
//         name: 'block1',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/hkLGkrlCEkGZeMQlnEkD.svg',
//           },
//         },
//       },
//       {
//         name: 'block2',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/bqyPRSZmhvrsfJrBvASi.svg',
//           },
//         },
//       },
//       {
//         name: 'block3',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/UcsyszzOabdCYDkoPPnM.svg',
//           },
//         },
//       },
//       {
//         name: 'block4',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/kRBeaICGexAmVjqBEqgw.svg',
//           },
//         },
//       },
//       {
//         name: 'block5',
//         className: 'block',
//         md: 8,
//         xs: 24,
//         children: {
//           wrapper: { className: 'block-content' },
//           img: {
//             children:
//               'https://gw.alipayobjects.com/zos/rmsportal/ftBIiyJcCHpHEioRvPsV.svg',
//           },
//         },
//       },
//     ],
//   },
// };
