

import React from 'react';
import construction from './images/construction.jpg'
import agriculture from './images/agriculture.jpg'
import surface from './images/surfacemodel.png'
import realEstate from './images/realEstate.jpeg'
import AI from './images/AI(1).jpeg'
import monument from './images/monument.jpeg'


export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: 'Explore Our Services', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: 'Empowering Businesses with Data Insights and AI-driven Solutions. Unleash the Power of Data Collection, AI, and 3D Mapping for Smarter Decision-making. Transform Your Business with Our Expertise and Drive Success in the Digital Era.',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: '',
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#title-wrapper',  // Scroll to the title-wrapper
          className: 'banner5-button',
          type: 'primary',
          children: 'Learn more',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};

export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    id: 'title-wrapper',  // Added id for scrolling
    className: 'title-wrapper',
    children: [
      { name: 'title', children: 'Areas of Operation', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: '',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    justify: 'center',
    children: [
      {
        name: 'block0',
        link: '/services/construction',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content',
            style: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
          },
          img: {
            children: construction,
            style: { width: '100%', objectFit:'contain', padding: 0 },
          },
          content: { children: 'Construction', style: { textAlign: 'center' } },
        },
      },
      {
        name: 'block1',
        link: '/services/agriculture',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content',
            style: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
          },
          img: {
            children: agriculture,
            style: { width: '100%', objectFit:'contain' , padding: 0},
          },
          content: { children: 'Agriculture', style: { textAlign: 'center' } },
        },
      },
      {
        name: 'block2',
        link: '/services/territorial',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content',
            style: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
          },
          img: {
            children: surface,
            style: { width: '90%', objectFit:'contain', padding: 0 },
          },
          content: { children: 'Territorial', style: { textAlign: 'center' } },
        },
      },
      {
        name: 'block3',
        link: '/services/ai-models',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content',
            style: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
          },
          img: {
            children: AI,
            style: { width: '90%', objectFit:'contain' , padding: 0},
          },
          content: { children: 'Specialized AI Models', style: { textAlign: 'center' } },
        },
      },
      {
        name: 'block4',
        link: '/services/monument-preservation',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content',
            style: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
          },
          img: {
            children: monument,
            style: { width: '100%', objectFit:'contain', padding: 0 },
          },
          content: { children: 'Monuments Preservation', style: { textAlign: 'center' } },
        },
      },
      {
        name: 'block5',
        link: '/services/real-state',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content',
            style: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
          },
          img: {
            children: realEstate,
            style: { width: '93%', objectFit:'contain', padding: 0 },
          },
          content: { children: 'Real State', style: { textAlign: 'center' } },
        },
      },
    ],
  },
  
};
