import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search,
      page_location: window.location.href
    });
  }, [location]);

  return null;
}

export default RouteChangeTracker;
